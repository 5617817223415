//Homepage
import React from "react";
import ExclusivePartners from "./ExclusivePartners";
import IsytechAuto from "../../../images/isytech-auto.jpg";
import IsytechAuto2 from "../../../images/isytech-auto2.jpg";
import IsytechAuto3 from "../../../images/isytech-auto3.jpg";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const images = [IsytechAuto, IsytechAuto2, IsytechAuto3]; // Use different images here

const Homepage = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nextSlide, setNextSlide] = useState(1);
  const [slideDirection, setSlideDirection] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideDirection("slide-out-left");
      setTimeout(() => {
        setNextSlide((prevSlide) => (prevSlide + 1) % images.length);
        setSlideDirection("slide-in-right");
      }, 1000); // Duration of the slide-out animation
    }, 5000); // Change slide every 5 seconds
  }, []);

  useEffect(() => {
    //Current slide, change to next slide after animation is done
    const timeout = setTimeout(() => {
      setCurrentSlide(nextSlide);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [nextSlide]);

  return (
    <div className="container-home">
      <section className="landing">
        <div className="landing-info">
          <div className="small-title">Isytech BV</div>
          <div>
            <div className="landing-title">We Sign for Safety</div>
            <div className="landing-subtitle">{t("landingText")}</div>
            <div className="landing-socials">
              <a
                href="https://www.facebook.com/profile.php?id=61556879200035"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare className="social-icon" />
              </a>
              <a
                href="https://www.linkedin.com/in/isytech-bv-320b532b9/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="social-icon" />
              </a>
            </div>
          </div>
          <div className="button-group">
            <Link to="/projects" className="button-full">
              {t("projects")}
            </Link>
            <Link to="/contact" className="button-outline left-5">
              {t("contact")}
            </Link>
          </div>
        </div>
        <div className="landing-carousel">
          <img
            src={images[currentSlide]}
            alt="Isytech Auto"
            className={`carousel-img`}
          />
          <img
            src={images[nextSlide]}
            alt="Isytech Auto"
            className={`carousel-img ${
              slideDirection === "slide-in-right" ? "slide-in-right" : ""
            }`}
          />
        </div>

        <a href="#sponsors" className="arrow-down">
          <IoIosArrowDown />
        </a>
      </section>
      <section id="sponsors" className="partners">
        <ExclusivePartners />
      </section>
    </div>
  );
};

export default Homepage;
