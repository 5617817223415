import React from "react";
import PartnerValtir from "../../../images/partners/Partner-Valtir.png";
import PartnerWhelen from "../../../images/partners/Partner-Whelen.png";
import PartnerPVL from "../../../images/partners/Partner-PVL.png";
import PartnerStilb from "../../../images/partners/Partner-Stilb.png";
import PartnerPilit from "../../../images/partners/Partner-pilit.png";
import PartnerIntav from "../../../images/partners/Partner-Intav.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Partner = ({ logo, name, desc, link }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="partner" data-aos="fade-up">
      <div className="partner-logo">
        <img src={logo} alt={name} />
      </div>
      <div className="partner-content">
        <div className="partner-name">{name}</div>
        <div className="partner-desc">{desc}</div>
        <a
          href={link}
          className="partner-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("moreInfo")}
        </a>
      </div>
    </div>
  );
};

const ExclusivePartners = () => {
  const { t } = useTranslation();

  return (
    <div className="exclusive-partners">
      <div className="landing-info">
        <div className="small-title mid">{t("partners")}</div>
        <div className="page-title"> {t("exclusivePartners")}</div>
        <div className="page-content">{t("partnersText")}</div>
      </div>
      <div className="grid-partners">
        <Partner
          logo={PartnerValtir}
          name="Valtir"
          desc={t("valtir")}
          link="https://www.valtir.com/"
        />

        <Partner
          logo={PartnerWhelen}
          name="Whelen"
          desc={t("whelen")}
          link="https://www.whelen.com/"
        />

        <Partner
          logo={PartnerPVL}
          name="PVL"
          desc={t("pvl")}
          link="https://www.pvluk.com/"
        />

        <Partner
          logo={PartnerPilit}
          name="pi-lit"
          desc={t("pilit")}
          link="https://pi-lit.com/"
        />

        <Partner
          logo={PartnerStilb}
          name="Stilb"
          desc={t("stilb")}
          link="https://www.stilbtechnologies.com/"
        />

        <Partner
          logo={PartnerIntav}
          name="Intav"
          desc={t("intav")}
          link=" https://www.intav-europe.com/"
        />
      </div>
    </div>
  );
};

export default ExclusivePartners;
