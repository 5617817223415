// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation files
import nlTranslation from "./locales/nl/translation.json";
import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    nl: { translation: nlTranslation },
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
  lng: "nl", // Default language
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
