import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer-part">
        <div className="footer-part-title">ISYTECH</div>
        <div className="footer-part-text">Oudenaardestraat 53</div>
        <div className="footer-part-text">B-8570 Anzegem</div>
        <div className="footer-part-text">{t("belgium")}</div>
        <div className="footer-part-text top-15">BTW: BE 0477.828.631</div>
      </div>
      <div className="footer-part">
        <div className="footer-part-title">{t("contact")}</div>
        <div className="footer-part-text">T +32 9 312 3040</div>
        <div className="footer-part-text">M +32 473 98 33 82</div>
        <div className="footer-part-text">info@isytech.be</div>
      </div>
      <div className="footer-part">
        <div className="footer-part-title">{t("followUs")}</div>
        <div className="footer-part-text">
          <a href="https://www.facebook.com/profile.php?id=61556879200035">
            Facebook
          </a>
        </div>
        <div className="footer-part-text">
          <a href="https://www.linkedin.com/in/isytech-bv-320b532b9/">
            LinkedIn
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
