import React, { useState } from "react";
import { Link } from "react-router-dom";
import smLogo from "../images/isytech_logo.jpg";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import Footer from "./Footer";
import ReactCountryFlag from "react-country-flag";

const NavLink = ({ label, to, isActive, onClick }) => (
  <Link
    onClick={onClick}
    className={`nav-link left-25 ${isActive ? "nav-active" : ""}`}
    to={to}
  >
    {label}
  </Link>
);

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = (page) => {
    setActive(page);
    setIsOpen(false); // Close the menu when a link is clicked
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng); // Change language
    //Close the menu when a language is selected
    setIsOpenLang(false);
  };

  const getCountryCode = (lang) => {
    switch (lang) {
      case "en":
        return "US";
      case "nl":
        return "BE";
      case "fr":
        return "FR";
      default:
        return "BE"; // Default to US if language is unknown
    }
  };

  return (
    <nav>
      <div className="nav-logo">
        <Link
          onClick={() => handleNavLinkClick("home")}
          className={`nav-link ${active === "home" ? "nav-active" : ""}`}
          to="/home"
        >
          <img src={smLogo} alt="ISYTECH Logo" />
          <span className="color-primary">ISY</span>
          <span className="italic color-secondary">TECH</span>
        </Link>
      </div>
      <div className="next">
        <div className="next">
          <div className="nav-right">
            <NavLink
              label={t("home")}
              to="/home"
              isActive={active === "home"}
              onClick={() => handleNavLinkClick("home")}
            />
            <NavLink
              label={t("about")}
              to="/about"
              isActive={active === "about"}
              onClick={() => handleNavLinkClick("about")}
            />
            <NavLink
              label={t("projects")}
              to="/projects"
              isActive={active === "projects"}
              onClick={() => handleNavLinkClick("projects")}
            />
            <NavLink
              label={t("contact")}
              to="/contact"
              isActive={active === "contact"}
              onClick={() => handleNavLinkClick("contact")}
            />
          </div>
          <div className="language-switcher left-25">
            <button
              className="language-switcher-btn"
              onClick={() => setIsOpenLang(!isOpenLang)}
            >
              <ReactCountryFlag
                countryCode={getCountryCode(i18n.language)}
                svg
                title={i18n.language}
              />
            </button>
            {isOpenLang && (
              <div className="language-dropdown">
                <div
                  className="language-option"
                  onClick={() => handleLanguageChange("nl")}
                >
                  <span className="flag">
                    <ReactCountryFlag countryCode="BE" svg title="BE" />
                    <div className="flag-name">BE</div>
                  </span>
                </div>
                <div
                  className="language-option"
                  onClick={() => handleLanguageChange("fr")}
                >
                  <span className="flag">
                    <ReactCountryFlag countryCode="FR" svg title="FR" />
                    <div className="flag-name">FR</div>
                  </span>
                </div>
                <div
                  className="language-option"
                  onClick={() => handleLanguageChange("en")}
                >
                  <span className="flag">
                    <ReactCountryFlag countryCode="US" svg title="US" />
                    <div className="flag-name">US</div>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ham-menu">
          <div className="nav-menu-icon" onClick={toggleMenu}>
            {isOpen ? <IoMdClose /> : <GiHamburgerMenu />}
          </div>
          <div className={`nav-popup ${isOpen ? "open" : ""}`}>
            <div className="nav-popup-upper">
              <div className="nav-popup-up">
                <img
                  src={smLogo}
                  className="nav-popup-logo"
                  alt="ISYTECH Logo"
                />
                <div className="nav-popup-title">
                  <span className="color-primary">ISY</span>
                  <span className="italic color-secondary">TECH</span>
                </div>
              </div>
              <NavLink
                label={t("home")}
                to="/home"
                isActive={active === "home"}
                onClick={() => handleNavLinkClick("home")}
              />
              <NavLink
                label={t("about")}
                to="/about"
                isActive={active === "about"}
                onClick={() => handleNavLinkClick("about")}
              />
              <NavLink
                label={t("projects")}
                to="/projects"
                isActive={active === "projects"}
                onClick={() => handleNavLinkClick("projects")}
              />
              <NavLink
                label={t("contact")}
                to="/contact"
                isActive={active === "contact"}
                onClick={() => handleNavLinkClick("contact")}
              />
            </div>

            <div className="bot">
              <footer>
                <div className="footer-part">
                  <div className="footer-part-title">{t("contact")}</div>
                  <div className="footer-part-text">T +32 9 312 3040</div>
                  <div className="footer-part-text">M +32 473 98 33 82</div>
                  <div className="footer-part-text">info@isytech.be</div>
                </div>
                <div className="footer-part">
                  <div className="footer-part-title">{t("followUs")}</div>
                  <div className="footer-part-text">
                    <a href="https://www.facebook.com/profile.php?id=61556879200035">
                      Facebook
                    </a>
                  </div>
                  <div className="footer-part-text">
                    <a href="https://www.linkedin.com/in/isytech-bv-320b532b9/">
                      LinkedIn
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
