//Not found page

import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found roman">
      <h1>404 {t("notFound")}</h1>
    </div>
  );
};

export default NotFound;
