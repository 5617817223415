import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaRegEye } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";
import AboutImage from "../../images/isytech-about.jpg";
import { useTranslation } from "react-i18next";

const AboutCard = ({ icon: Icon, title, content }) => (
  <section data-aos="fade-up" className="about-card">
    <Icon className="about-icon" />
    <h2>{title}</h2>
    {typeof content === "string" ? <p>{content}</p> : content}
  </section>
);

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <div div className="about-upper">
        <div className="about-information">
          <div className="small-title">{t("aboutUs")}</div>
          <div className="page-title">{t("aboutUsTitle")}</div>
          <div className="page-desc">{t("aboutUsText")}</div>
        </div>
        <div className="about-image">
          <img src={AboutImage} alt="About us" />
        </div>
      </div>
      <div className="about-content">
        <AboutCard
          icon={FaRegComment}
          title={t("missionTitle")}
          content={t("missionText")}
        />
        <AboutCard
          icon={FaRegEye}
          title={t("visionTitle")}
          content={t("visionText")}
        />
        <AboutCard
          icon={FaRegHeart}
          title={t("valuesTitle")}
          content={
            <ul>
              <li>
                <strong>{t("valuesTitle1")}</strong> {t("valuesText1")}
              </li>
              <li>
                <strong>{t("valuesTitle2")}</strong> {t("valuesText2")}
              </li>
              <li>
                <strong>{t("valuesTitle3")}</strong> {t("valuesText3")}
              </li>
              <li>
                <strong>{t("valuesTitle4")}</strong> {t("valuesText4")}
              </li>
            </ul>
          }
        />
      </div>
    </div>
  );
};

export default About;
