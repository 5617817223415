import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ContactCard = ({ icon: Icon, title, content }) => (
  <section data-aos="fade-up" className="about-card">
    <Icon className="about-icon" />
    <h2>{title}</h2>
    {typeof content === "string" ? <p>{content}</p> : content}
  </section>
);

const Contact = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact">
      <div className="contact-upper">
        <div className="contact-information">
          <div className="small-title">{t("contactSmall")}</div>
          <div className="page-title">{t("contactTitle")}</div>
          <div className="page-desc">{t("contactText")}</div>
        </div>
      </div>

      <div className="contact-content" data-aos="fade-up">
        <div className="contact-cards">
          <ContactCard
            icon={FaRegAddressCard}
            title={t("address")}
            content={
              <div className="contact-card-sub">
                <div>
                  <strong> {t("officeAndProduction")}</strong> Oudenaardestraat
                  53, B-8570 Anzegem, {t("belgium")}
                </div>
                <div>
                  <strong> {t("headquarters")}</strong> Vaddenhoek 8, B-9772
                  Wannegem-Lede, {t("belgium")}
                </div>
              </div>
            }
          />
          <ContactCard
            icon={BsTelephone}
            title={t("phone")}
            content={
              <div className="contact-card-sub">
                <div>
                  <strong>{t("phone")}:</strong> +32 9 312 3040
                </div>
                <div>
                  <strong>{t("manufacturing")}:</strong> +32 473 98 33 82
                </div>
              </div>
            }
          />{" "}
          <ContactCard
            icon={MdOutlineEmail}
            title={t("email")}
            content={
              <div className="contact-card-sub">
                <div>
                  <strong>{t("emailCompany")}</strong>
                  <a href="mailto:info@isytech.be">info@isytech.be</a>
                </div>
                <div>
                  <strong>{t("emailWim")}</strong>
                  <a href="mailto:wim@isytech.be">wim@isytech.be</a>
                </div>
              </div>
            }
          />
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.145840035411!2d3.5564982769283606!3d50.884011071678195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c30e83fb14858b%3A0xb4b9114f1b48da02!2sIsytech%20bvba!5e0!3m2!1snl!2sbe!4v1720774041607!5m2!1snl!2sbe"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
